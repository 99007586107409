<template>
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.98999 14H22.99" stroke="#FF7171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 23V26H2V2H18V5H20V1C20 0.45 19.55 0 19 0H1C0.45 0 0 0.45 0 1V27C0 27.55 0.45 28 1 28H19C19.55 28 20 27.55 20 27V23H18Z" fill="#FF7171"/>
        <path d="M22.99 14L18.99 18" stroke="#FF7171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.99 14L18.99 10" stroke="#FF7171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.99 6.09009V4.09009" stroke="#FF7171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.99 24V22" stroke="#FF7171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
    export default {
        name: "Logout"
    }
</script>

<style scoped>

</style>